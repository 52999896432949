<template>
  <div class="flex" data-cy="Questionnaire-Form-Upload">
    <a-card :bordered="true" class="w75 mb-20 mr-16">
      <div class="align-center divider-bottom pb-24 form-upload-title">
        <embed width="40px" class="mr-16" src="/download-upload-icon.svg">
        <div class="txt-22 txt-bold txt-font-din-medium w-100">
          {{ $t('components.titles.uploadAForm') }}
        </div>
        <a class="txt-orange txt-18 form-create-cancel justify-self-end"
          data-cy="cancel-form-upload"
          :disabled="isSupervisor"
          @click="cancelForm()">
          {{ $t('values.cancel') }}
        </a>
      </div>
      <a-tabs v-model="selectedTab">
        <a-tab-pane :key="1" :tab="$t('values.upload')" :disabled="!isSENS && forms && forms.length > 0">
          <div>
            <div v-if="!isSENS">
              <p class="mb-8 txt-bold txt-22">{{ $t('components.description.whichFormIntendUpload') }}</p>
              <div class="mb-16">
                {{ $t('components.description.allUploadedFormsMustAdhere') }} <b> {{ $t('components.description.pleaseNoteThatFormIdUnderSettings') }} </b>
              </div>
              <a-select
                v-model="indicatorSelected"
                data-cy="select-indicator"
                :placeholder="$t('values.select')"
                class="w270">
                <a-icon slot="suffixIcon" type="caret-down"/>
                <a-select-option data-cy="select-indicator-anthropometry" value="Anthropometry">{{ $t('components.titles.anthropometry') }}</a-select-option>
                <a-select-option data-cy="select-indicator-mortality" value="Anthropometry_Mortality">{{ $t('components.titles.anthropometryMortality') }} </a-select-option>
              </a-select>
            </div>
            <div v-if="isSENS" class="mt-16 txt-bold txt-22">
              <div v-if="isSENS">
                {{ $t('components.description.toStartDownloadModify') }}
                <a-list bordered item-layout="horizontal" :data-source="sensExcelList" class="mt-20">
                  <a-list-item slot="renderItem" slot-scope="item">
                    <a-list-item-meta
                      :description="item.description">
                      <a slot="title" :href="item.link">{{ item.name }}</a>
                    <a-avatar slot="avatar" style="backgroundColor:#fff">
                      <a :href="item.link">
                        <embed width="30px" src="/icon-cloud-download.svg" class="mt-10">
                      </a>
                    </a-avatar>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </div>
            </div>
            <div v-show="indicatorSelected || isSENS" class="divider mt-40">
              <div class="modal-introduction mt-22 mb-20 txt-bold txt-20">
                {{ $t('components.description.uploadTheSMARTFormTemplate') }}
              </div>
              <div id="form-new-drop-zone" ref="dropZone" :disabled="isSupervisor" :class="dropZoneClass">
                <div class="flex justify-center align-center">
                  {{ $t('components.description.dropModifiedTemplateHereOr') }}
                  <input ref="input" :disabled="isSupervisor" type="file" class="hidden ml-8 mr-8">
                  <a-button
                    class="ml-4 mr-4 pt-4 flex align-center justify-center align-center"
                    type="primary"
                    size="large"
                    :disabled="awaitingResponse || isSupervisor"
                    @click="$refs.input.click()">
                    {{ $t('components.description.chooseOne') }}
                  </a-button>
                  {{ $t('components.description.toUpload') }}
                </div>
                <div
                  v-if="file != null"
                  id="form-new-filename"
                  class="flex justify-center align-center">
                  {{ file != null ? file.name : '' }}
                  <a-button type="link" class="pt-4" @click="clearFile">
                    <a-icon type="close-square" theme="filled" class="txt-red txt-18"/>
                  </a-button>
                </div>
              </div>
              <a-checkbox v-model="downloaded" class="mt-20" :disabled="isSupervisor">
                {{ $t('components.description.downloadedUploadingSMARTTemplate') }}
              </a-checkbox>
              <a-button
                class="mt-40 mb-24 pt-4 pl-40 pr-40 flex align-center justify-center noBorder"
                type="primary"
                size="large"
                :disabled="!indicatorSelected || !downloaded || awaitingResponse || isSupervisor"
                data-cy="upload-form-button"
                @click="upload(false)">
                {{ $t('values.upload') }} <spinner :state="awaitingResponse"/>
              </a-button>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane
          :key="2"
          :tab="$t('components.notifications.formSent', {
            formLength: forms && forms.length || 0
          })"
          :disabled="forms && forms.length === 0">
          <p class="mt-16 txt-bold txt-22">
            {{ $t('components.description.yourSentForms') }}
          </p>
          <a-list
            v-if="forms && forms.length > 0"
            bordered
            item-layout="horizontal"
            :data-source="forms"
            class="mt-20">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta
                :title="item._data.name"
                :description="`Submissions: ${item._data.submissions}`"/>
              <a-popconfirm
                :title="$t('components.notifications.areYouSureYouWantToDeleteThisForm')"
                :disabled="isSupervisor"
                :ok-text="$t('values.yes')"
                :cancel-text="$t('values.no')"
                @confirm="deleteForm(item._data.xmlFormId)">
                <a-button
                  :type=" isSupervisor ? 'disabled' : 'danger'"
                  class="pt-4"
                  :disabled="isSupervisor"
                  shape="circle"
                  icon="delete"/>
              </a-popconfirm>
            </a-list-item>
          </a-list>
        </a-tab-pane>
      </a-tabs>
    </a-card>
    <a-card v-if="!isSENS && selectedTab === 1" :bordered="true" class="w25 mb-20">
      <div class="align-center pb-24 form-upload-title">
        <div class="txt-22 txt-bold txt-font-din-medium">
          {{ $t('components.titles.templates') }}
        </div>
      </div>
      <div
        v-if="indicatorSelected"
        id="download-template"
        class="flex flex-column align-center justify-center pt-40 pb-20 greyBackground">
        <embed width="40px" class="mr-16 mb-8" src="/download-cloud-icon.svg">
        <div data-cy="selected-indicator-file-title" class="txt-22 txt-bold txt-font-din-medium txt-center mb-8">
          {{ indicatorSelected === "Anthropometry" ? $t('components.titles.anthropometry') : $t('components.titles.anthropometryMortality') }}
        </div>
        <div class="flex justify-center align-center">
          <a-button
            class="ml-4 mr-4 pt-4 flex align-center justify-center buttonBorder"
            type="default"
            size="large"
            :disabled="awaitingResponse"
            data-cy="download-excel-file-button"
            @click="downloadTemplate()">
            {{ $t('components.titles.downloadExcelFile') }}
          </a-button>
        </div>
      </div>
      <div
        v-if="!indicatorSelected"
        id="no-template"
        class="flex flex-column align-center justify-center">
        {{ $t('components.description.aTemplateWillAppearHere') }}
      </div>
    </a-card>
  </div>
</template>

<script>
import * as uuid from 'uuid';
import { mapActions, mapGetters, mapState } from 'vuex';
import Form from '../../../../presenters/form';
import Spinner from '../../../spinner.vue';
import dropZone from '../../../../mixins/drop-zone';
import request from '../../../../mixins/request';
import { apiPaths } from '../../../../util/request';
import { requestData } from '../../../../store/modules/request';
import { noop } from '../../../../util/util';
import enumerators from '../../../../mixins/enumerators';

export default {
  name: 'QuestionnaireFormUpload',
  components: { Spinner },
  mixins: [
    dropZone({ keepAlive: false, eventNamespace: 'form-new' }),
    request(),
    enumerators()
  ],
  props: {
    survey: {
      type: Object,
      default: undefined
    },
    cancel: {
      type: Function,
      default: noop
    }
  },
  data() {
    return {
      fileIsOverDropZone: false,
      awaitingResponse: false,
      indicatorSelected: undefined,
      file: null,
      downloaded: false,
      selectedTab: 1,
      fullSmartExcelList: [
        {
          name: this.$t('components.titles.smartFormTemplate'),
          description: this.$t('components.description.pleaseProvideMeADescriptionForSmart'),
          link: '/smart_template_v1.xlsx'
        }
      ],
      sensExcelList: [
        {
          name: this.$t('components.titles.householdDemography'),
          description: this.$t('components.description.coveringModuleOneDemography'),
          link: '/GLO_V3_DM_EN_FR_AR_SW_04-XLS.xlsx'
        },
        {
          name: this.$t('components.titles.householdDemographyMortality'),
          description: this.$t('components.description.coveringModuleOneDemographyOptionalMortality'),
          link: '/GLO_V3_DM_MOR_EN_FR_AR_SW_04-XLS.xlsx'
        },
        {
          name: this.$t('components.titles.householdFoodSecurityMosquitoNetCoverage'),
          description: this.$t('components.description.coveringFoodSecurityModuleMosquitoNetCoverage'),
          link: '/GLO_V3_FS_NET_WS_EN_FR_AR_SW_04-XLS.xlsx'
        },
        {
          name: this.$t('components.titles.child'),
          description: this.$t('components.description.coveringModuleAnthropometryHealthIYCF'),
          link: '/GLO_V3_PH_Child_EN_FR_AR_SW_03-XLS.xlsx'
        },
        {
          name: this.$t('components.titles.woman'),
          description: this.$t('components.description.coveringModuleWomenPartAnthropometry'),
          link: '/GLO_V3_Women_EN_FR_AR_SW_03-XLS.xlsx'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['isSENS']),
    ...requestData(['forms']),
    ...mapState({
      metadata: state => state.survey.metadata || {},
      projectId: state => state.survey?.project?.id,
      surveyId: state => state.survey.surveyId,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    anthroMortalityTemplate() {
      const baseFileName659 = '/SMART_Survey_MORTA_ANTHRO_659_EN_FR_AR_SP_PT_10';
      const baseFileName059 = '/SMART_Survey_MORTA_ANTHRO_059_EN_FR_AR_SP_PT_10';
      const { includedAgeGroup = '6-59', allowGPS, samplingMethod } = this.metadata;
      if (includedAgeGroup === '6-59') {
        if (allowGPS) {
          if (samplingMethod === 'Cluster') {
            return `${baseFileName659}.xlsx`;
          }
          return `${baseFileName659}_GPS_NO_CLUSTER.xlsx`;
        }
        if (samplingMethod === 'Cluster') {
          return `${baseFileName659}_NO_GPS_CLUSTER.xlsx`;
        }
        return `${baseFileName659}_NO_GPS_NO_CLUSTER.xlsx`;
      }
      if (allowGPS) {
        if (samplingMethod === 'Cluster') {
          return `${baseFileName059}.xlsx`;
        }
        return `${baseFileName059}_GPS_NO_CLUSTER.xlsx`;
      }
      if (samplingMethod === 'Cluster') {
        return `${baseFileName059}_NO_GPS_CLUSTER.xlsx`;
      }
      return `${baseFileName059}_NO_GPS_NO_CLUSTER.xlsx`;
    },
    anthroTemplate() {
      const baseFileName659 = '/SMART_Survey_ANTHRO_659_EN_FR_AR_SP_PT_10';
      const baseFileName059 = '/SMART_Survey_ANTHRO_059_EN_FR_AR_SP_PT_10';
      const { includedAgeGroup = '6-59', allowGPS, samplingMethod } = this.metadata;
      if (includedAgeGroup === '6-59') {
        if (allowGPS) {
          if (samplingMethod === 'Cluster') {
            return `${baseFileName659}.xlsx`;
          }
          return `${baseFileName659}_GPS_NO_CLUSTER.xlsx`;
        }
        if (samplingMethod === 'Cluster') {
          return `${baseFileName659}_NO_GPS_CLUSTER.xlsx`;
        }
        return `${baseFileName659}_NO_GPS_NO_CLUSTER.xlsx`;
      }
      if (allowGPS) {
        if (samplingMethod === 'Cluster') {
          return `${baseFileName059}.xlsx`;
        }
        return `${baseFileName059}_GPS_NO_CLUSTER.xlsx`;
      }
      if (samplingMethod === 'Cluster') {
        return `${baseFileName059}_NO_GPS_CLUSTER.xlsx`;
      }
      return `${baseFileName059}_NO_GPS_NO_CLUSTER.xlsx`;
    },
    disabled() {
      return this.awaitingResponse;
    },
    dropZoneClass() {
      return {
        'form-new-disabled': this.awaitingResponse,
        'form-new-dragover': this.fileIsOverDropZone
      };
    },
    // Returns the inferred content type of the file based on its extension. (We
    // first tried using this.file.type rather than inferring the content type,
    // but that didn't work in Edge.)
    contentType() {
      const { name } = this.file;
      if (name.length >= 5 && name.slice(-5) === '.xlsx')
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      if (name.length >= 4 && name.slice(-4) === '.xls')
        return 'application/vnd.ms-excel';
      return 'application/xml';
    }
  },
  watch: {
    survey: {
      deep: true,
      handler() {
        this.getForms();
      }
    },
    state(state) {
      if (state) return;
      this.file = null;
      this.$refs.input.value = '';
    }
  },
  mounted() {
    // Using a jQuery event handler rather than a Vue one in order to facilitate
    // testing: it is possible to mock a jQuery event but not a Vue event.
    $(this.$refs.input).on('change.form-new', (event) => {
      this.afterFileSelection(event.target.files[0]);
    });
    this.getForms().then(() => {
      if (this.forms && this.forms.length > 0 && !this.isSENS) {
        this.selectedTab = 2;
      }
    });
    this.fetchEmumerators(this.projectId, this.surveyId);
  },
  beforeDestroy() {
    $(this.$refs.input).off('.form-new');
  },
  methods: {
    ...mapActions([
      'setForm',
      'loadSelectedIndicators',
      'resetAllIndicators',
      'setFormUploadAsChanged'
    ]),
    downloadTemplate() {
      if (this.indicatorSelected === 'Anthropometry') {
        window.location = this.anthroTemplate;
      } else {
        window.location = this.anthroMortalityTemplate;
      }
    },
    cancelForm() {
      if (this.forms && this.forms.length > 0) {
        const promises = this.forms
          .map(form => this.request({
            method: 'DELETE',
            url: apiPaths.form(this.survey._data.id, form._data.xmlFormId)
          }));
        Promise.all(promises).then(() => {
          this.file = null;
          this.$store.dispatch('get', [{
            key: 'projects',
            url: '/projects',
            extended: true
          }]);
        }).catch(() => {
          this.$alert().danger(this.$t('components.notifications.errorOccuredDeletedForms'));
        });
      }
      this.cancel();
    },
    afterFileSelection(file) {
      this.$alert().blank();
      this.file = file;
    },
    ondrop(jQueryEvent) {
      this.afterFileSelection(jQueryEvent.originalEvent.dataTransfer.files[0]);
    },
    upload() {
      if (this.file == null) {
        this.$alert().info('Please choose a file.');
        return;
      }
      const query = {
        ignoreWarnings: true,
        publish: true,
        surveyId: this.metadata.surveyId,
        indicatorSelected: this.indicatorSelected
      };

      const headers = { 'Content-Type': this.contentType };
      if (this.contentType !== 'application/xml')
        headers['X-XlsForm-FormId-Fallback'] = uuid.v4();
      this.request({
        method: 'POST',
        url: apiPaths.forms(this.survey?._data?.id, query),
        headers,
        data: this.file,
        fulfillProblem: noop,
        problemToAlert: noop,
        noAlert: true
      }).catch((err) => {
        this.$notification.error({
          message: this.$t('values.error'),
          description:
            `${this.$t('components.description.aFormattingErrorHasBeenIdentifiedInTheFile')}
            ${this.$t('components.description.pleaseEnsureYouAreUsingSMARTTemplate')}`,
          btn: h => h(
            'a-button',
            {
              props: {
                type: 'danger',
                size: 'small'
              },
              on: {
                click: () => this.$notification.error({
                  message: this.$t('values.error'),
                  description: err.response.data.message
                })
              }
            },
            'Details',
          )
        });
        return err;
      })
        .then(async ({ data }) => {
          if (data) {
            this.$emit('success', new Form(data));
            this.setFormUploadAsChanged();

            this.getForms();
            this.$store.dispatch('get', [{
              key: 'projects',
              url: '/projects',
              extended: true
            }]).catch(noop);
            this.clearFile();
            if (!this.isSENS) {
              this.selectedTab = 2;
            }

            this.loadSelectedIndicators({ surveyId: query.surveyId });
            this.$notification.success({
              message: `${this.$t('values.success')}!`,
              description:
                this.$t('components.description.formSavedSentToSmartCollectSuccessfully')
            });
          }
        });
    },
    async getForms() {
      if (this.survey && this.survey?._data?.id) {
        return this.$store.dispatch('get', [{
          key: 'forms',
          url: apiPaths.surveyForms(this.survey._data.id, this.surveyId),
          extended: true
        }]).catch(noop);
      }
      return null;
    },
    deleteForm(formId) {
      if (!this.forms) {
        return;
      }

      if (!formId) {
        return;
      }

      this.request({
        method: 'DELETE',
        url: apiPaths.form(this.survey._data.id, formId)
      })
        .then(() => {
          this.file = null;
          this.$emit('success', this.form);
          this.$store.dispatch('get', [{
            key: 'projects',
            url: '/projects',
            extended: true
          }]).catch(noop);

          this.setFormUploadAsChanged();
          this.resetAllIndicators(this.surveyId);

          if (this.forms.length === 1) {
            this.selectedTab = 1;
          }
        });
    },
    clearFile() {
      this.fileIsOverDropZone = false;
      this.awaitingResponse = false;
      this.file = null;
      this.downloaded = false;
      this.$refs.input.value = '';
    }
  }
};
</script>

<style lang="scss" scoped>
  .form-upload-title {
    display: grid;
    grid-template-columns: 50px auto 10%;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .disabledIndicators {
    opacity: 0.2;
    pointer-events: none;
  }

  .buttonBorder {
    border-width: 2px;
    background-color: #f7f7f7;
  }

  .noBorder {
    border-color: transparent;
  }

  .greyBackground {
    background-color: #f7f7f7;
  }
</style>
